import React from 'react';
import Glasses from './ARviews/Glasses';
import Cap from './ARviews/cap';
import Hijab from './ARviews/hijab';
import { useState } from 'react';

const App = () => {
  const [currentView, setCurrentView] = useState(null);
  return (
    <React.Fragment>
      <div className="container">
    <div className="menu">
       <a href="https://digiwearit.com"> 
        <img src="./images/cropped-logo.png" alt="DigiWearit" style={{height: "10vw", marginLeft: "20px"}}/>
      </a>
      
     
      <div style={{  position: 'absolute', top: '0%', alignItems: 'center', left: '40px'}}>
      <img src="./images/qr.jpeg" alt="QR" style={{height: "12vw", marginLeft: "20px"}}/>
      </div>
      <div style={{  position: 'absolute', top: '0%', alignItems: 'center', right: '40px'}}>
      <img src="./images/qr.jpeg" alt="QR" style={{height: "12vw", marginLeft: "20px"}}/>
      </div>
      <h4 id="feet-text">CHOOSE ONE & SHOW YOUR FACE IN THE CAMERA</h4>
      <div style={{  position: 'absolute', top: '100%', alignItems: 'center'}}>
      <img onClick={() => setCurrentView(<Glasses />)} src="./images/Glasses.png" alt="logo" style={{height: "7vw", marginLeft: "20px"}}/>
      <img onClick={() => setCurrentView(<Cap />)} src="./images/Cap.png" alt="logo" style={{height: "7vw", marginLeft: "20px"}}/>
      <img onClick={() => setCurrentView(<Hijab />)} src="./images/Hijab.png" alt="logo" style={{height: "7vw", marginLeft: "20px"}}/>
      </div>
    </div>
    {currentView}
  </div>
    </React.Fragment>
  );
};

export default App;