import React, {
  Suspense, useMemo, useRef, useState,
} from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import {
  FaceTracker,
  HeadMaskMesh,
  ZapparCamera,
  ZapparCanvas,
  Loader,
  Pipeline
} from '@zappar/zappar-react-three-fiber';
import objectSrc from '../assets/hijab.glb';

function Model() {
  const [gltf, set] = useState();
  useMemo(() => new GLTFLoader().load(objectSrc, set), []);

  if (gltf) {
    const { scene } = gltf;
    console.log(scene);
    const Helmet = scene.getObjectByName('Sketchfab_model');
    return <primitive position={[0, -0.5, -0.2]} rotation={[1.8708, 3.14159, 1,5708]} scale={[7.5, 7.5, 7.5]} object={Helmet} />;
  }
  return null;
}

function App() {
  const faceTrackerGroup = useRef();
  const pipeline = new Pipeline();
  return (
    <ZapparCanvas>
      <ZapparCamera pipeline={pipeline} userFacing rearCameraMirrorMode="css" />
      <FaceTracker ref={faceTrackerGroup} pipeline={pipeline}>
        <Suspense fallback={null}>
          <HeadMaskMesh trackerGroup={faceTrackerGroup} />
          <Model />
        </Suspense>
      </FaceTracker>
      <ambientLight intensity={0.6} />
      <directionalLight position={[2, 8, 1.5]} intensity={1.5} />
      <Loader />
    </ZapparCanvas>
  );
}

export default App;
